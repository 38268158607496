.image-slide {
  min-height: 500px !important;
  background-size: cover !important;
  text-decoration: none;
}

.image-slide-content {
  text-decoration: none !important;
}

.image-slide-content p {
  text-decoration: none !important;
}

.image-slide-link {
  text-decoration: none !important;
}

@media(min-width: 992px) {
  .image-slide {
    min-height: 400px !important;
    background-size: contain !important;
  }
}


.image-slide hr {
  border: 1px solid white;
  width: 10%;
  margin: 0 auto;
}

.image-slide-type {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 12pt !important;
  text-align: center;
  margin-top: 30px;
  color: white;
  word-wrap: break-word;
  text-transform: uppercase;
}

@media(min-width: 992px) {
  .image-slide-type {
    font-weight: 200;
  }
}

.image-slide-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 34pt !important;
  text-align: center;
  margin-top: 20px;
  color: white;
  word-wrap: break-word;
  line-height: 33pt;
  text-transform: uppercase;
}

.image-slide-sub-title {
  font-family: 'Crimson Text', serif;
  font-size: 22pt !important;
  text-align: center;
  margin-top: 10px;
  color: white;
  font-weight: 200;
  word-wrap: break-word;
  font-style: italic;
}

.image-slide-sub-subtitle {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 12pt !important;
  text-align: center;
  margin-top: 20px;
  color: white;
  word-wrap: break-word;
}

@media(min-width: 992px) {
  .image-slide-sub-subtitle {
    font-weight: 200;
  }
}

.image-slide-button {
  color:white;
  margin: 0 auto;
  border-color: white;
  margin-bottom: 30px;
  box-shadow: none;
}

.image-slide-button:hover {
  background-color: #ff5a34 !important;
  border-color: #ff5a34 !important;
}

.image-slide-button:active {
  background-color: #ff5a34 !important;
  border-color: #ff5a34 !important;
}

.image-slide-button:focus {
  box-shadow: none;
}

.image-slide-fullheight-row {
  height:100%;
  overflow: hidden;
  min-height: 500px !important;
}

@media(min-width: 992px) {
  .image-slide-fullheight-row {
    min-height: 400px !important;
  }
}

@media(min-width: 992px) {
  .image-slide-content {
    padding: 30px;
  }
}
