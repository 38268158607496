.home-page-meeting-times {
  text-align: center;
  padding: 50px 45px;
}

@media(min-width: 992px) {
  .home-page-meeting-times{
    padding: 50px 100px;
  }
}

.home-page-meeting-times h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  color:#ff5a34;
}

.home-page-meeting-times h3 {
  font-family: 'Crimson Text', serif;
}

.what-to-expect-button {
  color:#ff5a34;
  margin: 0 auto;
  border-color: #ff5a34;
  margin-bottom: 30px;
  box-shadow: none;
}

.what-to-expect-button:hover {
  background-color: #ff5a34 !important;
  border-color: #ff5a34 !important;
  color:white;
}

.what-to-expect-button:active {
  background-color: ##ff5a34 !important;
  border-color: #ff5a34 !important;
  color:white;
}

.what-to-expect-button:focus {
  box-shadow: none;
}
