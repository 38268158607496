a {
  color:#ff5a34
}

a:hover {
  color:black
}

.row {
  margin-left: 0;
  margin-right: 0;
}

body {
  padding-top: 90px;;
}

@media(min-width: 992px) {
  body {
    padding-top: 110px;
  }
}
