.sbc-navbar {
  background-color: white !important;
  padding-left: 45px !important;
  padding-right: 45px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

@media(min-width: 992px) {
  .sbc-navbar{
    padding-left: 100px !important;
    padding-right: 100px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

.sbc-navbar-logo {
  width:auto;
  height:40px;
}

@media(min-width: 330px) {
  .sbc-navbar-logo {
    width:auto;
    height:45px;
  }
}

@media(min-width: 992px) {
  .sbc-navbar-logo {
    width:auto;
    height:60px;
  }
}

.sbc-navbar-collapse {
  align-self: flex-end;
}

.nav-item {
  padding-left: 10px;
  padding-right: 10px;
}

.nav-link.active {
  border-bottom: 1px solid #ff5a34;
}

.nav-link {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
}

.login-btn {
  font-family: 'Raleway', sans-serif;
  font-weight: 500 !important;
  border-style: none !important;
}
